/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from 'react';
import useIsomorphicLayoutEffect from '@/utils/useIsomorphicEffect';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import FocusBox from '../FocusBox';
import TitleShadow from '../TitleShadow';

gsap.registerPlugin(ScrollTrigger);

const WeFocus = () => {
  const focusContainerRef = useRef<HTMLDivElement>(null);
  useIsomorphicLayoutEffect(() => {
    const titleTl = gsap.timeline({
      scrollTrigger: {
        trigger: focusContainerRef.current,
        start: '-50px center',
        end: 'top center',
        // markers: true,
        // toggleActions: "restart pause reverse pause",
        // scrub: 1, // scrub is use set the amount of time to catch up, it kinda looks the scroll animation with ur scroller
      },
    });
    titleTl
      .addLabel('start')
      .from(focusContainerRef.current, { opacity: 0, translateX: '-100px' })
      .addLabel('entering')
      .to(focusContainerRef.current, { opacity: 1, translateX: '0px', duration: 0.5 })
      .addLabel('end');
    gsap.set('.focus-el-list', { opacity: 0, y: 20 });
    ScrollTrigger.batch('.focus-el-list', {
      start: '-160px center',
      end: 'top center',
      // markers: true,
      onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15 }),
    });
  }, []);

  return (
    <div className="section-focus bg-author-base pt-24">
      <div
        className="focus-container container mx-auto max-w-screen-3xl px-6 py-14"
        ref={focusContainerRef}
      >
        <TitleShadow
          prologue="ความสนใจ"
          colorPrologue="#EFEFEF"
          title="เราให้ความสนใจกับ"
          colorTitle="#000000"
          subtitle="I'm focus on"
          colorSubtitle="#000000"
        />
      </div>
      <div className="focus-el mx-auto grid w-full max-w-screen-3xl grid-cols-1 lg:grid-cols-2 xl:grid-cols-6">
        <FocusBox
          className="xl:col-span-2"
          title="นวัตกรรมและเทคโนโลยี"
          prologue="01"
          colorPrologue="#81CFFB"
          colorTitle="#000000"
          description={
            <p>
              เราให้ความสนใจในนวัตกรรมและเทคโนโลยีที่ทันสมัยอยู่ตลอดเวลา
              เพื่อค้นหาแนวทางใหม่ ๆ หรือนำเสนอแนวคิดใหม่ ๆ
              ในการพัฒนาและสร้างสรรค์ผลงานที่เป็นเอกลักษณ์และมีคุณภาพ
              เพื่อตอบสนองความต้องการของลูกค้าอย่างเต็มที่
            </p>
          }
          borderless
        />
        <FocusBox
          className="xl:col-span-2"
          title="ทีมงานที่มีความเชี่ยวชาญ"
          prologue="02"
          colorPrologue="#FFDAC4"
          colorTitle="#000000"
          description={
            <p>
              เรามีทีมงานพัฒนาที่มีความเชี่ยวชาญทางเทคนิคสูง
              มีความรู้และความสามารถในการเลือกใช้เทคโนโลยีและเครื่องมือที่ทันสมัย
              เพื่อให้ผลงานมีความเสถียรและมีประสิทธิภาพสูง
            </p>
          }
          borderless
        />
        <FocusBox
          className="xl:col-span-2"
          title="ความพึงพอใจของลูกค้า"
          prologue="03"
          colorPrologue="#6BC8C8"
          colorTitle="#000000"
          description={
            <p>
              เรามุ่งเน้นในการให้บริการที่ตอบสนองความต้องการ ของลูกค้าอย่างเต็มที่
              ตั้งแต่เริ่มโปรเจกต์ไปจนถึงการ ดูแลและบริการหลังจบโปรเจกต์
              ซึ่งรวมถึงการให้คำปรึกษาแนะนำและให้ความสำคัญในความพึงพอใจของลูกค้าทุกคน
            </p>
          }
          borderless
        />
        <FocusBox
          className="xl:col-span-3"
          title="ความรับผิดชอบในงาน"
          prologue="04"
          colorPrologue="#FFBDB5"
          colorTitle="#000000"
          description={
            <p>
              เราให้ความสำคัญต่อความรับผิดชอบในหน้าที่สำหรับการบริหารโครงการ
              ภายใต้กรอบของงบประมาณและเวลาที่ตรงตามกำหนดโดยลูกค้าสามารถตรวจสอบและติดตามความคืบหน้าในการทำงานได้อย่างต่อเนื่อง
              และรวมถึงความรับผิดชอบต่อผลงานโดยการรับประกันคุณภาพให้กับลูกค้า
            </p>
          }
          borderless
        />
        <FocusBox
          className="xl:col-span-3"
          title="การทำงานร่วมกัน"
          prologue="05"
          colorPrologue="#FFE2A4"
          colorTitle="#000000"
          description={
            <p>
              เราส่งเสริมในการทำงานร่วมกันอย่างมีประสิทธิภาพระหว่างทีมงานกับลูกค้าและผู้เกี่ยวข้อง
              โดยมุ่งเน้นเรื่องความสัมพันธ์ที่ดีต่อกัน
              รวมถึงการสื่อสารและการทำความเข้าใจในงานกันได้อย่างถูกต้องและโปร่งใส
            </p>
          }
          borderless
        />
      </div>
    </div>
  );
};

export default WeFocus;
