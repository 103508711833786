import styled from 'styled-components';

export const StyleDrawer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: block;
    &.drawer-overlay-open {
      opacity: 1;
    }
  }
`;
export const StyleDrawerContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  &.drawer-close {
    transform: translateX(-100%);
  }
  &.drawer-open {
    transform: translateX(0);
  }
  .drawer-content {
    position: relative;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    .drawer-content__header {
      padding-top: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #e8e8e8;
      .drawer-content__header-close {
        cursor: pointer;
      }
    }
    .drawer-content__body {
      padding: 24px;
    }
    .header-temp__menu-item {
      a {
        &:active,
        &:hover {
          background-color: #1990ff;
          color: #fff;
        }
      }
    }
  }
`;
